import { Link } from "react-router-dom";
import React from "react";
export default function Navbar() {
  return (
    <>
      <header className="focus:ring-4 focus:outline-none p-2 bg-yellow-400 text-white">
        <nav className="border-gray-200 container mx-auto">
          <div className="flex flex-wrap items-center justify-between p-4">
            <Link
              to="/"
              className="flex items-center space-x-3 rtl:space-x-reverse"
            >
              <img src="/navbar.png" className="h-15" alt="floraoasis.site" />
              {/* <h1 className="font-extrabold text-center text-5xl ">Web 06</h1> */}
            </Link>
          </div>
        </nav>
      </header>
    </>
  );
}
